export default [
  {
    path: 'banner',
    label: 'Home',
  },
  {
    path: 'top10',
    label: 'Top 10',
  },
  {
    path: 'contact',
    label: 'Redes',
  },
  {
    path: 'faq',
    label: 'FAQ'
  }
];
